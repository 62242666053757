import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Informe, InformeBusqueda } from 'src/app/demo/api/informes';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Auth } from 'aws-amplify';
import { UsuarioDto } from '../models';
import { ValidacionInformeDF } from '../models/ValidacionInformeDF';
import { Requerimiento } from '../api/requerimientos';
interface Modelo_Vacio {
    page: number;
    quantity: number;
    fechaInicio: Date | null;
    fechaFin: Date | null;
}
@Injectable({
    providedIn: 'root',
})
export class InformeService {
    API_URL = environment.API_URL;
    public usuario = new BehaviorSubject<string>('false');
    constructor(private http: HttpClient) {}

    /**
     * Metodo que hace el proceso de login contra el backend
     * @param correo
     * @param credencial
     */
    async validar(correo: string, credencial: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.signIn({
                username: correo ?? '',
                password: credencial,
            })
                .then((resp) => {
                    return resolve(resp);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
    getUserInfo(cognitoId: string) {
        const parametros = {
            params: new HttpParams().set('cognitoId', cognitoId),
        };
        return this.http
            .get<UsuarioDto>(`${this.API_URL}/admin/usuarios/`, parametros)
            .pipe(
                map((res: UsuarioDto) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }

    /**
     * Metodo que hace el proceso de login contra el backend
     * @param user
     * @param oldPassword
     * @param newPassword
     */
    public resetPassword(user: string, newPassword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.completeNewPassword(user, newPassword)
                .then((resp) => {
                    return resolve(resp);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    getInformes(informes: InformeBusqueda, esAdmin: boolean) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('page', informes.page);
        queryParams = queryParams.append('quantity', informes.quantity);
        if (informes.codigo)
            queryParams = queryParams.append('codigoInforme', informes.codigo);
        if (esAdmin && informes.institucion) {
            queryParams = queryParams.append(
                'codigoInstitucion',
                informes.institucion
            );
        }

        if (informes.anio)
            queryParams = queryParams.append('anno', informes.anio);
        return this.http
            .get<any>(`${this.API_URL}/admin/informes`, { params: queryParams })
            .pipe(
                map((res: any) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }

    handleError(Error: HttpErrorResponse) {
        let errorMessage = '';
        if (Error.error instanceof ErrorEvent) {
            errorMessage = Error.error.message;
        } else {
            errorMessage = `Error Code: ${Error.status}\nMessage: ${Error.message}`;
        }
        return throwError(errorMessage);
    }

    /**
     * Metodo que regresa un informe especifico basado en su UUID
     *
     */
    getInforme(id: string): Observable<Informe> {
        return this.http
            .get<Informe>(`${this.API_URL}/admin/informe/` + id)
            .pipe(
                map((res: Informe) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }
    getRequerimiento(codigo: string): Observable<Requerimiento> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('codigo', codigo);
        return this.http
            .get<Requerimiento>(`${this.API_URL}/admin/requerimiento`, {
                params: queryParams,
            })
            .pipe(
                map((res: Requerimiento) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }
    /**
     *
     * @param informe Metodo que hace el proceso de update
     * @returns
     */
    updateInforme(
        informe: Informe,
        est: string,
        just: string
    ): Observable<any> {
        const body = {
            estado: est,
            justificacion: just,
            codigo: informe.codigo,
        };
        return this.http
            .put(
                //`resources/actualizarEstado/` +
                `${this.API_URL}/admin/informe/` + informe.uuid,
                body
            )
            .pipe(
                map((res: any) => {
                    return res || {};
                })
                // catchError(this.handleError)
            );
    }

    getErrores(filters: Modelo_Vacio, idUsuario?: number | null) {
        let queryParams = new HttpParams();

        queryParams = queryParams.append('page', filters.page);
        queryParams = queryParams.append('quantity', filters.quantity);
        if (filters.fechaInicio)
            queryParams = queryParams.append(
                'fechaInicio',
                filters.fechaInicio.getTime()
            );
        if (filters.fechaFin)
            queryParams = queryParams.append(
                'fechaFin',
                filters.fechaFin.getTime()
            );
        if (idUsuario) {
            queryParams = queryParams.append('idUsuario', idUsuario);
        }
        return this.http
            .get(
                //'resources/listadoErrores',
                `${this.API_URL}/historial/erroresDF`,
                { params: queryParams }
            )
            .pipe(
                map((res: any) => {
                    return res || {};
                })
                // catchError(this.handleError)
            );
    }
}
