/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_2dwd0wRjE',
    aws_user_pools_web_client_id: '5tlcjmmrhmjeinhmfms4eoud1p',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['NAME', 'EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_UPPERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
        ],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
