<p-toast></p-toast>
<div class="layout-topbar">
    <a class="app-logo" routerLink="/dash">
        <img alt="app logo" [src]="logo" />
        <span class="app-name">Banco Central de la Reserva</span>
    </a>

    <button
        #menubutton
        class="topbar-menubutton p-link"
        type="button"
        (click)="onMenuButtonClick()"
    >
        <span></span>
    </button>

    <ul class="topbar-menu">
        <li *ngFor="let item of tabs; let i = index">
            <a
                [routerLink]="item.routerLink"
                routerLinkActive="active-route"
                [routerLinkActiveOptions]="
                    item.routerLinkActiveOptions || {
                        paths: 'exact',
                        queryParams: 'ignored',
                        fragment: 'ignored',
                        matrixParams: 'ignored'
                    }
                "
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment!"
                [skipLocationChange]="item.skipLocationChange!"
                [replaceUrl]="item.replaceUrl!"
                [state]="item.state"
                [queryParams]="item.queryParams"
            >
                <span>{{ item.label }}</span>
            </a>
            <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
        </li>
    </ul>

    <div class="topbar-profile">
        <div class="flex">
            <div>
                <span class="profile-details">
                    <span class="profile-name">{{ getUser() }}</span>
                    <span class="profile-job"
                        ><div class="flex">
                            <div *ngIf="!this.esAdminGNR">{{ getRol() }} /</div>
                            <div *ngIf="this.esAdminGNR">
                                Administrador General
                            </div>
                            <div
                                class="flex align-content-center w-2rem pl-3"
                                *ngIf="this.cargando"
                            >
                                <p-progressSpinner
                                    styleClass="w-1rem h-1rem"
                                ></p-progressSpinner>
                            </div>
                            <div
                                *ngIf="
                                    !esAdminGNR &&
                                    this.esAdmin &&
                                    !this.cargando
                                "
                            >
                                {{
                                    obtenerAmbienteUsuario(this.idAmbiente)
                                        ?.name
                                }}
                            </div>
                            <div
                                *ngIf="
                                    !esAdminGNR &&
                                    this.ambientesUsuarioNormal.length === 1 &&
                                    !this.cargando
                                "
                            >
                                {{
                                    obtenerAmbienteUsuario(
                                        this.ambientesUsuarioNormal[0].ambiente
                                    )?.name
                                }}
                            </div>
                            <div
                                class="flex"
                                *ngIf="
                                    !esAdminGNR &&
                                    !this.esAdmin &&
                                    !this.cargando &&
                                    this.ambientesUsuarioNormal.length > 1
                                "
                            >
                                <div>Ambientes</div>
                                <div class="align-content-center">
                                    <button
                                        class="p-link"
                                        style="
                                            color: rgb(64, 68, 72);
                                            padding-top: 2px;
                                        "
                                        type="button"
                                        pStyleClass="@next"
                                        enterClass="hidden"
                                        enterActiveClass="scalein"
                                        leaveToClass="hidden"
                                        leaveActiveClass="fadeout"
                                        [hideOnOutsideClick]="true"
                                    >
                                        <i class="pi pi-angle-down"></i>
                                    </button>
                                    <ul
                                        class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto"
                                    >
                                        <li
                                            *ngFor="
                                                let ambiente of this
                                                    .ambientesUsuarioNormal
                                            "
                                        >
                                            <p>
                                                {{
                                                    obtenerAmbienteUsuario(
                                                        ambiente.ambiente
                                                    )?.name
                                                }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </span>
                </span>
            </div>
            <div>
                <button
                    class="topbar-profile-button p-link"
                    type="button"
                    pStyleClass="@next"
                    enterClass="hidden"
                    enterActiveClass="scalein"
                    leaveToClass="hidden"
                    leaveActiveClass="fadeout"
                    [hideOnOutsideClick]="true"
                >
                    <i class="pi pi-angle-down"></i>
                </button>
                <ul
                    class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto"
                >
                    <li>
                        <!--             <a
                            pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                            (click)="perfil()"
                        >
                            <i class="pi pi-user mr-3"></i>
                            <span>Perfil</span>
                        </a> -->
                        <form>
                            <a
                                pRipple
                                class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                                (click)="logout()"
                            >
                                <i class="pi pi-power-off mr-3"></i>
                                <span>Salir</span>
                            </a>
                        </form>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <p-dialog
        header="Mi Perfil"
        [(visible)]="displayPerfil"
        [modal]="true"
        [style]="{ width: '650px' }"
        class="p-fluid"
    >
        <ng-template pTemplate="content">
            <div class="field">
                <label for="addEmail">Correo</label>
                <input
                    type="text"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="correo"
                    maxlength="100"
                    id="addEmail"
                />
            </div>
            <div class="field">
                <label for="credencial">Credencial</label>

                <p-password [(ngModel)]="credencial">
                    <ng-template pTemplate="header">
                        <h6>Escriba su nueva clave</h6>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p-divider></p-divider>
                        <p class="mt-2">Sugerencias</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li>Al menos una minúscula</li>
                            <li>Al menos una mayúscula</li>
                            <li>Al menos un número</li>
                            <li>Al menos 8 caracteres</li>
                        </ul>
                    </ng-template>
                </p-password>
            </div>

            <div class="field">
                <label for="credencial">Confirme Credencial</label>
                <input
                    type="password"
                    id="credencial2"
                    name="credencial2"
                    maxlength="16"
                    [(ngModel)]="confirmeCredencial"
                    pInputText
                    placeholder="Confirme Credencial"
                    class="w-full"
                />
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button
                pButton
                pRipple
                label="Guardar"
                icon="pi pi-check"
                class="p-button-text"
                (click)="actualizarPerfil()"
            ></button>
            <button
                pButton
                pRipple
                label="Cerrar"
                icon="pi pi-times"
                class="p-button-text"
                (click)="displayPerfil = false"
            ></button>
        </ng-template>
    </p-dialog>
</div>
