import {Component} from '@angular/core';
import {LayoutService} from "./service/app.layout.service";
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: true
})
export class AppFooterComponent {
  labelAmbiente = environment.LABEL;

    constructor(public layoutService: LayoutService) {}

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }
}
