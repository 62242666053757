import { Component, ElementRef, ViewChild } from '@angular/core';
import {
    ConfirmationService,
    MenuItem,
    MessageService,
    SharedModule,
} from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import {
    ActivatedRoute,
    Router,
    RouterLink,
    RouterLinkActive,
} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UsuarioService } from '../demo/service/usuario.service';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { CommonModule, NgFor } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { AuthService } from '../demo/service/auth.service';
import {
    ADMrolId,
    ANArolId,
    BACrolId,
    GNRrolId,
    VALrolId,
} from '../demo/components/constants';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [MessageService, ConfirmationService],
    standalone: true,
    imports: [
        ToastModule,
        RouterLink,
        NgFor,
        RouterLinkActive,
        StyleClassModule,
        RippleModule,
        FormsModule,
        DialogModule,
        SharedModule,
        InputTextModule,
        PasswordModule,
        DividerModule,
        ButtonModule,
        CommonModule,
        ProgressSpinnerModule,
    ],
})
export class AppTopBarComponent {
    menu: MenuItem[] = [];

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    searchActive: boolean = false;
    displayPerfil: boolean = false;
    cargando = true;
    correo: any = '';
    credencial: string = '';
    confirmeCredencial: string = '';
    idAmbiente: number = 0;
    ambientes: any[] = [];
    ambienteusaurio: string = '';
    esAdmin: boolean = false;
    ambientesUsuarioNormal: any[] = [];
    esAdminGNR: boolean = false;
    constructor(
        public layoutService: LayoutService,
        private messageService: MessageService,
        private authService: AuthService,
        private router: Router,
        private usuarioService: UsuarioService,
        private activatedRoute: ActivatedRoute
    ) {
        this.isAdmin();
        this.getTipoAdmin();
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config.layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config.colorScheme;
    }

    get logo(): string {
        const path = 'assets/layout/images/logo_BCR-11.svg';
        return path;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    getUser() {
        return sessionStorage.getItem('user');
    }

    getRol(): string {
        let rol = sessionStorage.getItem('rol');
        if (+rol! == ADMrolId) return 'Administrador';
        else if (+rol! == ANArolId) return 'Analista';
        else if (+rol! == VALrolId) return 'Validador';
        else if (+rol! == BACrolId) return 'Entidad';
        else if (+rol! == GNRrolId) return 'Administrador General';
        else return '';
    }

    /**
     *  Hacemos el logout del sistema
     * @returns
     */
    logout(): void {
        sessionStorage.removeItem('rol');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('menu');

        this.authService.cerrarSesion().subscribe(
            (value) => {
                this.router.navigate(['/auth/login']);
            },
            (error) => {
                console.log(error);
                if (this.activatedRoute.snapshot.url.join('') != '') {
                    this.router.navigate(['/auth/login']);
                }
            }
        );

        this.router.navigate(['/auth/login']);
    }

    /**
     * Metodo que se invoca cuando deseamos hacer una edición de
     * nuestro perfil
     */
    perfil() {
        this.displayPerfil = true;
        this.correo = sessionStorage.getItem('user');
    }

    /**
     * Metodo que actualiza la información del perfil
     */
    actualizarPerfil() {
        if (
            this.credencial == null ||
            this.confirmeCredencial == null ||
            this.credencial.length < 8 ||
            this.confirmeCredencial.length < 8
        ) {
            // Las credenciales están vacias
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Las credenciales no cumplen los requerimientos mínimos.',
                life: 3000,
            });
        } else {
            this.usuarioService.cambioClave(this.credencial).subscribe(
                (resp) => {
                    this.displayPerfil = false;

                    this.messageService.add({
                        severity: 'info',
                        summary: 'Información',
                        detail: 'Credenciales actualizadas exitosamente.',
                        life: 3000,
                    });
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Se detecto un error al actualizar las credenciales',
                        life: 3000,
                    });
                    this.displayPerfil = true;
                }
            );
        }
    }

    credencialesIguales() {
        if (this.credencial === this.confirmeCredencial) {
            return true;
        } else {
            return false;
        }
    }

    obtenerAmbientes() {
        this.usuarioService.getAmbientes().subscribe(
            (resp) => {
                for (const element of resp) {
                    this.ambientes.push({
                        code: element.id,
                        name: element.nombre,
                    });
                }
            },
            (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Ha fallado el catálogo de ambeintes',
                    life: 3000,
                });
            }
        );
    }

    getAmbienteUsuarioAdmin() {
        this.authService
            .ambienteUsuarioAdmin()
            .then((ambiente) => {
                this.idAmbiente = ambiente;
                this.cargando = false;
            })
            .catch(() => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Se detecto un error al obtener el ambiente del usuario',
                    life: 3000,
                });
            });
    }
    getAmbientesUsuario() {
        this.authService
            .ambienteUsuario()
            .then((ambientes) => {
                this.ambientesUsuarioNormal = ambientes;
                this.cargando = false;
            })
            .catch(() => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Se detecto un error al obtener el ambiente del usuario',
                    life: 3000,
                });
            });
    }

    isAdmin() {
        this.authService
            .isAdmin()
            .then((isAdmin) => {
                this.esAdmin = isAdmin;
                this.obtenerAmbientes();
                if (isAdmin) {
                    this.getAmbienteUsuarioAdmin();
                } else {
                    this.getAmbientesUsuario();
                }
            })
            .catch((error) => {
                console.error(error);
                return false;
            });
    }
    getTipoAdmin() {
        this.authService
            .isAdminGNR()
            .then((isAdminGNR) => {
                this.esAdminGNR = isAdminGNR;
            })
            .catch((error) => {
                console.error(error);
                return false;
            });
    }
    public obtenerAmbienteUsuario(idAmbiente: number) {
        return this.ambientes.find((ambiente) => {
            return ambiente.code === idAmbiente;
        });
    }
}
