import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AppMenuitemComponent } from './app.menuitem.component';
import { NgFor } from '@angular/common';
import { Menu } from '../demo/models';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: true,
    imports: [NgFor, AppMenuitemComponent],
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService) {}

    ngOnInit() {
        const jsonString = sessionStorage.getItem('menu');
        const menu: Menu[] = JSON.parse(jsonString || '[]');
        const reporteriaItem: any = menu.find(
            (item) => item.label === 'Reporteria'
        );
        if (reporteriaItem) {
            reporteriaItem['data'] = { fullPage: true };
        }
        this.model = menu.map((menu) => {
            if (menu.items && menu.items.length === 0) {
                const { items, ...menuSinItems } = menu;
                return menuSinItems;
            } else {
                return menu;
            }
        });
    }
}
