import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    REGION = environment.REGION;
    constructor(private authService: AuthService, private router: Router) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return new Observable<HttpEvent<any>>((subscriber) => {
            this.authService.obtenerToken().subscribe((value) => {
                if (value && value.getJwtToken()) {
                    const token = value.getJwtToken();
                    if (
                        !(
                            request.method.toLowerCase().includes('put') &&
                            request.url
                                .toLowerCase()
                                .includes(`.s3.${this.REGION}.amazonaws.com`)
                        )
                    ) {
                        // Si tenemos un token se pone en el header directamente
                        request = request.clone({
                            setHeaders: { Authorization: `bearer ${token}` },
                        });
                    } else {
                        console.log(
                            '%cInterceptor ignora peticion: ' + request.url,
                            'color:gray;'
                        );
                    }
                } else {
                    console.log(
                        '%cNo hay sesion detectada, no se adjunta el token...',
                        'color:yellow;'
                    );
                }
                next.handle(request)
                    .pipe(
                        catchError((err) => {
                            if (err instanceof HttpErrorResponse) {
                                console.log(
                                    '%cError en interceptor: ' + err.message,
                                    'color:blue;'
                                );
                                if (err.status === 200) {
                                    subscriber.complete();
                                }
                            }
                            subscriber.error(err);
                            return throwError(err);
                        })
                    )
                    .subscribe((event) => {
                        subscriber.next(event);
                    });
            });
        });
    }
}
