import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './demo/service/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    labelAmbiente = environment.LABEL;

    private loggedInSubscription!: Subscription;

    constructor(
        private primengConfig: PrimeNGConfig,
        private config: PrimeNGConfig,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.primengConfig.ripple = true;

        this.config.setTranslation({
            accept: 'Aceptar',
            reject: 'Cancelar',
            dayNames: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
            ],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            monthNames: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Setiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            monthNamesShort: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ],
            dateFormat: 'dd/mm/yy',
            today: 'Hoy',
            clear: 'Borrar',
            weekHeader: 'Sm',

            //translations
        });

        this.authService.obtenerSesion().subscribe((sesionActiva) => {
            if (!sesionActiva) {
                this.router.navigate(['/auth/login']);
            }
        });
    }

    ngOnDestroy() {
        this.loggedInSubscription.unsubscribe();
    }
}
