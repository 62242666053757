export const ADMrolId = 1;
export const VALrolId = 2;
export const ANArolId = 3;
export const BACrolId = 4;
export const GNRrolId = 5;

export const roles = [
    { id: 1, rol: 'ADM', rolName: 'Administrador' },
    { id: 2, rol: 'VAL', rolName: 'Validador' },
    { id: 3, rol: 'ANA', rolName: 'Analista' },
    { id: 4, rol: 'BAC', rolName: 'Carga' },
    {
        id: 5,
        rol: 'GNR',
        rolName: 'Administrador general',
    },
];
export const rolesDp = [
    {
        label: 'Interno',
        value: 'I',
        items: [
            { id: 1, rol: 'ADM', rolName: 'Administrador' },
            { id: 2, rol: 'VAL', rolName: 'Validador' },
            { id: 3, rol: 'ANA', rolName: 'Analista' },
            {
                id: 5,
                rol: 'GNR',
                rolName: 'Administrador general',
            },
        ],
    },
    {
        label: 'Externo',
        value: 'E',
        items: [{ id: 4, rol: 'BAC', rolName: 'Carga' }],
    },
];

export const MODELO_INFORME_VACIO = {
    page: 1,
    quantity: 5,
    codigo: '',
    institucion: '',
    anio: '',
};

export const MODELO_DIAS_NO_HABILES_VACIO = {
    page: 1,
    quantity: 10,
    descripcion: '',
};

export const MODELO_CALENDARIO_VACIO = {
    page: 1,
    quantity: 10,
};
export const MODELO_HISTORICO_VACIO = {
    page: 1,
    quantity: 10,
    fechaInicio: null,
    fechaFin: null,
};
export const MODELO_AUDITORIA_VACIO = {
    page: 1,
    quantity: 10,
    ambiente: 0,
    categoria: '',
    accion: '',
    institucion: '',
    reporte: '',
    usuarioAccion: '',
    fechaInicio: null,
    fechaFin: null,
};
