import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './demo/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
            { path: 'inicio', pathMatch: 'full', redirectTo: '/auth/login' },
            {
                path: 'dash',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import(
                        './demo/components/dashboards/dashboards-routes'
                    ).then((m) => m.DashboardsRoutes),
            },
            {
                path: 'informes',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Informes' },
                loadChildren: () =>
                    import('./demo/components/informes/informes-routes').then(
                        (m) => m.InformesRoutes
                    ),
            },
            {
                path: 'instituciones',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Instituciones' },
                loadChildren: () =>
                    import(
                        './demo/components/instituciones/instituciones-routes'
                    ).then((m) => m.InstitucionesRoutes),
            },
            {
                path: 'cuentas',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Cuentas' },
                loadChildren: () =>
                    import('./demo/components/cuentas/cuentas-routes').then(
                        (m) => m.CuentasRoutes
                    ),
            },
            {
                path: 'municipios',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Municipios' },
                loadChildren: () =>
                    import(
                        './demo/components/municipios/municipio-routes'
                    ).then((m) => m.MunicipiosRoutes),
            },
            {
                path: 'requerimientos',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Requerimientos de Información' },
                loadChildren: () =>
                    import(
                        './demo/components/requerimientos/requerimientos-routes'
                    ).then((m) => m.RequerimientosRoutes),
            },
            {
                path: 'asignarReporte',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Asingacion de requerimiento a usuarios' },
                loadChildren: () =>
                    import(
                        './demo/components/asignarReporte/asignarReporte-routes'
                    ).then((m) => m.AsignarReporteRoutes),
            },
            {
                path: 'usuarios',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Usuarios' },
                loadChildren: () =>
                    import('./demo/components/usuarios/usuarios-routes').then(
                        (m) => m.UsuariosRoutes
                    ),
            },
            {
                path: 'diasnohabiles',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Días no hábiles' },
                loadChildren: () =>
                    import(
                        './demo/components/diasnohabiles/diasnohabiles-routes'
                    ).then((m) => m.DiasNoHabliesRoutes),
            },
            {
                path: 'calendario',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Calendario' },
                loadChildren: () =>
                    import(
                        './demo/components/calendario/calendario-routes'
                    ).then((m) => m.CalendarioRoutes),
            },
            {
                path: 'auditoria',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Auditoria' },
                loadChildren: () =>
                    import('./demo/components/auditoria/auditoria-routes').then(
                        (m) => m.AuditoriaRoutes
                    ),
            },
            {
                path: 'errores',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Historial' },
                loadChildren: () =>
                    import(
                        './demo/components/historicoErrores/historico-routes'
                    ).then((m) => m.HistoricoErroresRoutes),
            },
        ],
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () =>
            import('./demo/components/auth/auth-routes').then(
                (m) => m.AuthRoutes
            ),
    },
    {
        path: 'notfound',
        loadChildren: () =>
            import('./demo/components/notfound/not-found-route').then(
                (m) => m.NotFoundRoute
            ),
    },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
