import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/demo/api/usuarios';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsuarioService {
    API_URL = environment.API_URL;

    constructor(private http: HttpClient) {}

    getUsuarios(): Observable<any> {
        return this.http.get(`${this.API_URL}/admin/usuarios`).pipe(
            map((res: any) => {
                return res || {};
            }),
            catchError(this.handleError)
        );
    }

    getAmbientes(): Observable<any> {
        return this.http.get(`${this.API_URL}/admin/ambientes`).pipe(
            map((res: any) => {
                return res || {};
            }),
            catchError(this.handleError)
        );
    }

    handleError(Error: HttpErrorResponse) {
        let errorMessage = '';
        if (Error.error instanceof ErrorEvent) {
            errorMessage = Error.error.message;
        } else {
            errorMessage = `Error Code: ${Error.status}\nMessage: ${Error.message}`;
        }
        return throwError(errorMessage);
    }

    /**
     *
     * @param informe Metodo que hace el proceso de update
     * @returns
     */
    update(inst: Usuario): Observable<any> {
        const body = {
            nombre: inst.nombre,
            correo: inst.correo,
            idRol: inst.idRol,
            codigoEntidad: inst.codigoEntidad,
            estado: inst.estado,
            idAmbienteList: inst.ambientes,
        };
        return this.http
            .put(
                //`resources/actualizarUsuarios/`
                `${this.API_URL}/admin/usuarios/` + inst.correo,
                body
            )
            .pipe(
                map((res: any) => {
                    return res || {};
                })
                // catchError(this.handleError)
            );
    }

    /**
     * Metodo que realiza la insercion de un usuario en backend
     * @param usuario
     */
    addUserDB(usuario: Usuario): Observable<any> {
        const body = {
            nombre: usuario.nombre,
            correo: usuario.correo,
            cognitoId: usuario.cognitoId,
            idRol: usuario.idRol,
            codigoEntidad: usuario.codigoEntidad,
            idAmbienteList: usuario.ambientes,
        };

        return this.http
            .post(
                //`resources/crearUsuarios`,
                `${this.API_URL}/admin/usuarios`,
                body
            )
            .pipe(
                map((res: any) => {
                    return res || {};
                })
            );
    }

    createUserBdCognito(usuario: Usuario): Observable<any> {
        return this.http
            .post(`${this.API_URL}/admin/usuariosCognito`, usuario)
            .pipe(
                map((res: any) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }

    getUserByInstitucion(institucion: string) {
        const parametros = {
            params: new HttpParams().set('institucion', institucion),
        };
        return this.http
            .get<Array<Usuario>>(`${this.API_URL}/admin/usuarios/`, parametros)
            .pipe(
                map((res: Array<Usuario>) => {
                    return res || {};
                }),
                catchError(this.handleError)
            );
    }

    /**
     * Metodo que realiza la ejecución para el cambio de las credenciales del usuario
     * @param credencial
     */
    cambioClave(credencial: string) {
        const body = {
            clave: credencial,
        };
        return this.http.post(`${this.API_URL}/admin/cambioclave`, body).pipe(
            map((res: any) => {
                return res || {};
            })
            // catchError(this.handleError)
        );
    }
}
