import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    private status: any;
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        rutaActiva: ActivatedRouteSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.comprobarSesion(rutaActiva);
        // navigate to login page as user is not authenticated
    }

    public comprobarSesion(rutaActiva: ActivatedRouteSnapshot) {
        return new Observable<boolean>((subscriber) => {
            let ruta = rutaActiva.url.join(''); // obtenemos la ruta que está tratando de acceder
            // primero detectamos si hay una sesion
            this.authService.obtenerSesion().subscribe((sesionActiva) => {
                if (sesionActiva) {
                    //truthy, puede ser false o un objeto
                    // si hay una sesion entonces validamos el acceso
                    subscriber.next(true);
                    subscriber.complete();
                } else {
                    // si hay error en cualquier caso da igual, va logs y acceso denegado
                    console.log(
                        '%cAuthGuard: No se detecta sesion activa...',
                        'color:cyan'
                    );
                    this.router.navigate(['/auth/login']);
                    subscriber.next(false);
                    subscriber.complete();
                }
            });
        });
    }
}
