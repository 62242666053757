import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import {
    CognitoAccessToken,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { InformeService } from './informe.service';
import { ADMrolId, GNRrolId } from '../components/constants';
import { Ambiente } from '../models/Ambiente';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    API_URL = environment.API_URL;
    public usuario = new BehaviorSubject<string>('false');

    constructor(
        private http: HttpClient,
        private informeService: InformeService
    ) {}

    /**
     * Valida la sesion del usuario, retorna un observable que, en caso de que el usuario tenga una sesion activa, retorna
     * el objeto de la sesion, en caso contrario retorna false
     */
    public obtenerSesion(): Observable<CognitoUserSession | boolean> {
        return new Observable<CognitoUserSession | boolean>((subscriber) => {
            // Esta linea lanza error si el usuario no esta autenticado
            Auth.currentSession()
                .then((value: CognitoUserSession) => {
                    subscriber.next(value);
                    subscriber.complete();
                })
                .catch((reason) => {
                    subscriber.next(false);
                    console.log(
                        'Usuario sin sesion valida: ',
                        JSON.stringify(reason)
                    );
                });
        });
    }

    public forgotPassword(companyEmail: string): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            Auth.forgotPassword(companyEmail)
                .then((result) => {
                    // Resolve with the result if successful
                    resolve(result);
                })
                .catch((error) => {
                    // Reject with the error if there is an issue
                    reject(error);
                    console.log('error signing up:', error);
                });
        });
    }

    public forgotPasswordAndSubmit(
        username: string,
        code: string,
        newPassword: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(username, code, newPassword)
                .then((result) => {
                    // Resolve with the result if successful
                    resolve(result);
                })
                .catch((error) => {
                    // Reject with the error if there is an issue
                    reject(error);
                    console.log('error signing up:', error);
                });
        });
    }

    public async isAdmin(): Promise<boolean> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return (
                    userInfo &&
                    (userInfo.idRol === ADMrolId || userInfo.idRol === GNRrolId)
                );
            }
            return false;
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return false;
        }
    }

    public async isAdminGNR(): Promise<boolean> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return userInfo && userInfo.idRol === GNRrolId;
            }
            return false;
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return false;
        }
    }

    public async ambienteUsuarioAdmin(): Promise<number> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return +userInfo.ambientes[0].ambiente;
            }
            return 0;
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return 0;
        }
    }

    public async ambienteUsuario(): Promise<Ambiente[]> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return userInfo.ambientes;
            }
            return [];
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return [];
        }
    }

    public async rolUsuario(): Promise<number> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return +userInfo.idRol;
            }
            return 0;
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return 0;
        }
    }

    public async idUsuario(): Promise<number> {
        try {
            const idCognito = await firstValueFrom(this.obtenerIdCognito());
            if (idCognito) {
                const userInfo = await firstValueFrom(
                    this.informeService.getUserInfo(idCognito.toString())
                );
                return +userInfo.codigo;
            }
            return 0;
        } catch (error) {
            console.error('Error al verificar el rol del usuario:', error);
            return 0;
        }
    }

    public obtenerIdCognito(): Observable<string | boolean> {
        return new Observable<string | boolean>((subscriber) => {
            // Esta linea lanza error si el usuario no esta autenticado
            Auth.currentSession()
                .then((value: CognitoUserSession) => {
                    subscriber.next(value.getIdToken().payload['sub']);
                    subscriber.complete();
                })
                .catch((reason) => {
                    subscriber.next(false);
                    console.log(
                        'Usuario sin sesion valida: ',
                        JSON.stringify(reason)
                    );
                });
        });
    }

    /**
     * Obtiene el token para la sesion activa del usuario, lanza un error si el usuario no tiene una sesion activa
     */
    public obtenerToken(): Observable<CognitoAccessToken> {
        return new Observable<CognitoAccessToken>((subscriber) => {
            Auth.currentSession()
                .then((value: CognitoUserSession) => {
                    subscriber.next(value.getAccessToken());
                    subscriber.complete();
                })
                .catch((reason) => {
                    console.log(reason);
                    subscriber.next(undefined);
                    subscriber.complete();
                });
        });
    }

    public cerrarSesion(): Observable<CognitoUserSession | boolean> {
        return new Observable<CognitoUserSession | boolean>((subscriber) => {
            // Esta linea lanza error si el usuario no esta autenticado
            Auth.signOut()
                .then((value: CognitoUserSession) => {
                    subscriber.next(value);
                    subscriber.complete();
                })
                .catch((reason) => {
                    subscriber.error(reason);
                    subscriber.complete();
                });
        });
    }
}
